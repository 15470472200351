<template>
  <div class="be-table">
    <div v-if="!hideFilters" class="row d-print-none">
      <div class="col-12 col-md-auto">
        <be-form-group
          v-if="items.length > localPerPage || filters.length > 0"
          :label="$t('components.shared.be_table.search')"
          :label-for="`${id}-filter-search`"
        >
          <be-input-group>
            <be-input-group-prepend>
              <be-input-group-text class="bg-transparent pr-0">
                <i class="search-icon fal fa-search" />
              </be-input-group-text>
            </be-input-group-prepend>

            <be-form-input
              :id="`${id}-filter-search`"
              v-model="localSearch"
              type="search"
              :placeholder="$t('components.shared.be_table.type_to_search')"
              class="border-left-0"
            />
          </be-input-group>
        </be-form-group>
      </div>

      <div
        v-for="filter in filters"
        :key="filter.field"
        class="col-12 col-md-auto"
      >
        <table-filter
          :filter="filter"
          :items="items"
          @change="onFilterChange"
        />
      </div>

      <div v-if="$slots.filters" class="col-12 col-md">
        <slot name="filters" />
      </div>
    </div>

    <div class="table-responsive m-0">
      <table :id="id" :class="computedTableClasses">
        <thead :class="theadClass">
          <tr :class="theadTrClass">
            <th
              v-if="selectable"
              class="col-shrink text-center"
              @click.prevent="selectAll"
            >
              <be-form-checkbox
                v-be-tooltip="{
                  title: allSelected
                    ? $t('buttons.toggle_all_selection.deselect_all')
                    : $t('buttons.toggle_all_selection.select_all'),
                }"
                :model-value="allSelected"
                :disabled="!items.length"
                autocomplete="off"
              />
            </th>

            <th
              v-for="field in computedFields"
              :key="field.key"
              v-be-tooltip="{
                title: field?.tooltip || field?.abbr,
                placement: 'top',
                disabled: !field?.tooltip && !field.abbr,
              }"
              :abbr="field.abbr"
              :class="[
                {
                  [`text-${field.align}`]: field.align,
                  'cursor-pointer text-nowrap': field.sortable,
                  'vertical-align-middle': selectable,
                },
                field.class,
                field.thClass,
              ]"
              :tabindex="field.sortable ? 0 : null"
              @click="sortByField(field)"
              @keydown="onThKeyDown($event, field)"
            >
              <div class="d-inline-block">
                <slot :name="`head(${field.key})`" :field="field">
                  <span>{{ field.label }}</span>
                </slot>
              </div>

              <template v-if="field.sortable">
                <i
                  v-if="
                    localSortBy === field.key && localSortDirection === 'asc'
                  "
                  class="fas fa-fw fa-arrow-up-long"
                />

                <i
                  v-else-if="
                    localSortBy === field.key && localSortDirection === 'desc'
                  "
                  class="fas fa-fw fa-arrow-down-long"
                />

                <i
                  v-else
                  class="fas fa-fw fa-arrow-up-arrow-down text-black-50"
                />
              </template>
            </th>
          </tr>
        </thead>

        <tbody ref="tbody" :class="tbodyClass">
          <tr
            v-for="(item, itemIndex) in filteredItems"
            :key="rowKey(item, itemIndex)"
            :class="
              typeof tbodyTrClass === 'function'
                ? tbodyTrClass(item, itemIndex)
                : tbodyTrClass
            "
            :tabindex="selectable || hasRowClickedListener ? 0 : null"
            @click="!filterEvent($event) && onRowClick($event, item, itemIndex)"
            @keydown="onRowKeyDown($event, item)"
          >
            <td
              v-if="selectable"
              class="col-shrink text-center"
              @click.prevent="selectItem(item)"
            >
              <be-form-group
                :label="localSelectableSrLabel"
                label-sr-only
                class="m-0"
              >
                <be-form-checkbox
                  :model-value="itemIsSelected(item)"
                  tabindex="-1"
                  autocomplete="off"
                />
              </be-form-group>
            </td>

            <td
              v-for="field in computedFields"
              :key="field.key"
              :class="[field.class, field.tdClass]"
            >
              <slot
                :name="convertToKebabCase(field.key)"
                v-bind="{
                  item,
                  field,
                  index: itemIndex,
                }"
              >
                <template v-if="!hasSlot(field.key)">
                  {{ formatItem(item, String(field.key), field.formatter) }}
                </template>
              </slot>
            </td>
          </tr>

          <tr v-if="items.length === 0">
            <td
              :colspan="computedFields.length + (selectable ? 1 : 0)"
              class="text-center"
            >
              <slot name="empty">
                {{ localEmptyText }}
              </slot>
            </td>
          </tr>

          <tr v-if="filteredItems.length === 0 && items.length !== 0">
            <td
              :colspan="computedFields.length + (selectable ? 1 : 0)"
              class="text-center"
            >
              <slot name="empty-filtered">
                {{ localEmptyFilteredText }}
              </slot>
            </td>
          </tr>
        </tbody>

        <tfoot v-if="showFooter">
          <slot name="footer" />
        </tfoot>
      </table>
    </div>

    <div class="d-flex justify-content-between align-items-center">
      <be-pagination
        v-if="!hidePagination && localItems.length > localPerPage"
        v-model="localCurrentPage"
        :per-page="localPerPage"
        :total-rows="localItems.length"
        :aria-controls="id"
        class="my-2"
        @change="onPaginationChange"
      />

      <p
        v-if="computedActiveFiltersCount > 0"
        class="text-muted font-italic px-1 py-2 my-2 ml-auto"
      >
        {{
          $t(
            "components.shared.be_table.active_filter_w_count",
            {
              count: computedActiveFiltersCount,
              filteredCount: filteredItems.length,
              totalCount: items.length,
            },
            computedActiveFiltersCount
          )
        }}
      </p>
    </div>
  </div>
</template>

<script>
import Fuse from "fuse.js";
import { generateId } from "@/utils/id";
import filterEvent from "@/utils/filter-event";
import { format } from "date-fns";
import { convertToKebabCase } from "@/utils/text-utils";
import {
  KEY_CODE_DOWN,
  KEY_CODE_END,
  KEY_CODE_ENTER,
  KEY_CODE_HOME,
  KEY_CODE_SPACE,
  KEY_CODE_UP,
} from "@/constants/key-codes";
import TableFilter from "@/components/shared/be_table/TableFilter.vue";

// Helper methods

const sanitizePerPage = (value) => {
  const perPage = parseInt(value, 10);

  // Default to 20 if NaN
  if (Number.isNaN(perPage)) {
    return 10;
  }

  return perPage;
};

const getObjectKeysAndSubKeys = (item) => {
  // Get all keys and subkeys from an item
  // E.g. { id: 1, user: { name: "John" } } => ["id", "user.name"]

  const keys = [];

  const getKeys = (obj, path = "") => {
    for (const key in obj) {
      if (typeof obj[key] === "object") {
        getKeys(obj[key], `${path}${key}.`);
      } else {
        keys.push(`${path}${key}`);
      }
    }
  };

  getKeys(item);

  return keys;
};

export default {
  name: "BeTable",

  components: {
    TableFilter,
  },

  props: {
    currentPage: {
      type: [Number, String],
      required: false,
      default: 1,
    },

    emptyFilteredText: {
      type: String,
      required: false,
      default: undefined, // $t("components.shared.be_table.no_results")
    },

    emptyText: {
      type: String,
      required: false,
      default: undefined, // $t("components.shared.be_table.no_data")
    },

    fields: {
      type: Array,
      required: false,
      default: () => [],
    },

    filters: {
      type: Array,
      required: false,
      default: () => [],
    },

    hideFilters: {
      type: Boolean,
      required: false,
      default: false,
    },

    hidePagination: {
      type: Boolean,
      required: false,
      default: false,
    },

    id: {
      type: String,
      required: false,
      default: () => generateId("be-table"),
    },

    items: {
      type: Array,
      required: false,
      default: () => [],
    },

    perPage: {
      type: [Number, String],
      required: false,
      default: 10,

      validator: (value) => {
        return !Number.isNaN(parseInt(value, 10)) && parseInt(value, 10) > 0;
      },
    },

    preSelectedItems: {
      type: Array,
      required: false,
      default: () => [],
    },

    search: {
      type: String,
      required: false,
      default: "",
    },

    selectable: {
      type: Boolean,
      required: false,
      default: false,
    },

    selectableSrLabel: {
      type: String,
      required: false,
      default: undefined, // $t("components.shared.be_table.select_row")
    },

    small: {
      type: Boolean,
      required: false,
      default: false,
    },

    sortBy: {
      type: String,
      required: false,
      default: null,
    },

    sortDesc: {
      type: Boolean,
      required: false,
      default: false,
    },

    tableClass: {
      type: [Array, Object, String],
      required: false,
      default: undefined,
    },

    tbodyClass: {
      type: [Array, Object, String],
      required: false,
      default: undefined,
    },

    tbodyTrClass: {
      type: [Array, Function, Object, String],
      required: false,
      default: undefined,
    },

    theadClass: {
      type: [Array, Object, String],
      required: false,
      default: undefined,
    },

    theadTrClass: {
      type: [Array, Object, String],
      required: false,
      default: undefined,
    },

    uniqueRowKey: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ["filter-changed", "page-changed", "row-clicked", "selected"],

  data() {
    return {
      activeFilters: [],
      localCurrentPage: this.currentPage,
      localItems: this.cloneDeep(this.items),
      localPerPage: sanitizePerPage(this.perPage),
      localSearch: this.search || "",
      localSortBy: this.sortBy,
      localSortDirection: this.sortDesc ? "desc" : "asc",
      selectedItems: this.cloneDeep(this.preSelectedItems),
    };
  },

  computed: {
    allSelected() {
      return this.selectedItems.length === this.filteredItems.length;
    },

    computedActiveFiltersCount() {
      return this.localSearch
        ? this.activeFilters.length + 1
        : this.activeFilters.length;
    },

    computedFields() {
      if (!this.fields.length && this.items.length) {
        return Object.keys(this.items[0]).map((key) => ({
          key,
          label: this.normalizeLabel(key),
        }));
      } else if (this.fields.length && this.fields.every((f) => !f.key)) {
        return this.fields.map((f) => {
          if (typeof f === "string") {
            return {
              key: f,
              label: this.normalizeLabel(f),
            };
          }
        });
      } else {
        return this.fields;
      }
    },

    computedTableClasses() {
      return ["table", "table-hover", "table-striped", this.tableClass];
    },

    filteredItems() {
      // Copy items array
      let items = [...this.localItems];

      // Sort items
      items = this.sortItems(items);

      // Paginate items
      if (!this.hidePagination && items.length > this.localPerPage) {
        items = this.paginateItems(items);
      }

      return items;
    },

    hasRowClickedListener() {
      return !!this.$.vnode?.props?.onRowClicked;
    },

    localEmptyFilteredText() {
      if (this.emptyFilteredText === undefined) {
        return this.$t("components.shared.be_table.no_results");
      }

      return this.emptyFilteredText;
    },

    localEmptyText() {
      if (this.emptyText === undefined) {
        return this.$t("components.shared.be_table.no_data");
      }

      return this.emptyText;
    },

    localSelectableSrLabel() {
      if (this.selectableSrLabel === undefined) {
        return this.$t("components.shared.be_table.select_row");
      }

      return this.selectableSrLabel;
    },

    numberOfPages() {
      return Math.ceil(this.items.length / this.localPerPage);
    },

    showFooter() {
      return this.$slots.footer;
    },
  },

  watch: {
    localCurrentPage(value) {
      if (value > this.numberOfPages) {
        this.localCurrentPage = this.numberOfPages;
      }

      this.$emit("page-changed", this.localCurrentPage);
    },

    localSearch() {
      this.localItems = this.searchItems(this.items);
    },

    items: {
      handler() {
        this.localItems = this.cloneDeep(this.items);
      },

      deep: true,
    },

    perPage() {
      this.localPerPage = sanitizePerPage(this.perPage);
    },

    search() {
      this.localSearch = this.search;
    },

    sortBy() {
      this.localSortBy = this.sortBy;
    },

    sortDesc() {
      this.localSortDirection = this.sortDesc ? "desc" : "asc";
    },
  },

  mounted() {
    if (this.search) {
      this.localItems = this.searchItems(this.items);
    }
  },

  methods: {
    convertToKebabCase,
    filterEvent,

    compareStrings(a, b) {
      return new Intl.Collator(this.$i18n.locale, {
        numeric: true,
        sensitivity: "base",
      }).compare(a, b);
    },

    focusFirstRow(event) {
      event.preventDefault();

      const firstRow = this.$refs.tbody.querySelector("tr");

      if (firstRow) {
        firstRow.focus();
      }
    },

    focusLastRow(event) {
      event.preventDefault();

      const lastRow = this.$refs.tbody.querySelector("tr:last-child");

      if (lastRow) {
        lastRow.focus();
      }
    },

    focusNextRow(event) {
      event.preventDefault();

      const currentRow = event.target.closest("tr");
      const nextRow = currentRow.nextElementSibling;

      if (nextRow) {
        nextRow.focus();
      }
    },

    focusPreviousRow(event) {
      event.preventDefault();

      const currentRow = event.target.closest("tr");
      const previousRow = currentRow.previousElementSibling;

      if (previousRow) {
        previousRow.focus();
      }
    },

    formatItem(item, fieldKey, formatter) {
      const value = this.getNestedValue(item, fieldKey);

      if (formatter && typeof formatter === "function") {
        return formatter(value, fieldKey, item);
      } else {
        return value;
      }
    },

    getNestedValue(obj, path) {
      // Get nested value from object, e.g. "user.name"
      // If nested value is null, return empty string
      return path.split(".").reduce((o, i) => o?.[i] ?? "", obj);
    },

    hasSlot(name) {
      return this.$slots[name];
    },

    itemIsSelected(item) {
      return this.selectedItems.includes(item);
    },

    normalizeLabel(label) {
      return label
        .replace(/_/g, " ")
        .replace(/-/g, " ")
        .replace(/([A-Z])/g, " $1")
        .replace(/^./, (str) => str.toUpperCase());
    },

    onFilterChange({ filter, value }) {
      if (value) {
        this.activeFilters.push({ filter, value });
      } else {
        this.activeFilters = this.activeFilters.filter(
          (activeFilter) => activeFilter.filter !== filter
        );
      }

      this.localItems = this.items.filter((item) => {
        if (!filter) {
          return true;
        }

        // Date
        if (filter.type === "date") {
          const itemDate = format(new Date(item[filter.field]), "yyyy-MM-dd");
          const valueDate = format(new Date(value), "yyyy-MM-dd");

          return itemDate === valueDate;
        }

        // Date range
        if (filter.type === "date-range") {
          const itemDate = format(new Date(item[filter.field]), "yyyy-MM-dd");
          const startDate = format(new Date(value.start), "yyyy-MM-dd");
          const endDate = format(new Date(value.end), "yyyy-MM-dd");

          if (value.start && value.end) {
            return itemDate >= startDate && itemDate <= endDate;
          } else if (value.start) {
            return itemDate >= startDate;
          } else if (value.end) {
            return itemDate <= endDate;
          }
        }

        // Select
        if (filter.type === "select") {
          return value ? item[filter.field] === value : true;
        }

        return true;
      });

      this.$emit("filter-changed", this.activeFilters);
    },

    onPaginationChange(page) {
      this.$emit("page-changed", page);
    },

    onRowClick(event, item, index) {
      if (this.selectable) {
        this.selectItem(item);
      }

      this.$emit("row-clicked", item, index, event);
    },

    onRowKeyDown(event, item) {
      const { shiftKey } = event;

      switch (event.keyCode) {
        case KEY_CODE_ENTER:
        case KEY_CODE_SPACE:
          this.onRowClick(item);
          break;

        case KEY_CODE_UP:
          this.focusPreviousRow(event);
          break;

        case KEY_CODE_DOWN:
          this.focusNextRow(event);
          break;

        case KEY_CODE_HOME:
        case KEY_CODE_UP && shiftKey:
          this.focusFirstRow(event);
          break;

        case KEY_CODE_END:
        case KEY_CODE_DOWN && shiftKey:
          this.focusLastRow(event);
          break;
      }
    },

    onThKeyDown(event, field) {
      if (
        event.keyCode === KEY_CODE_ENTER ||
        event.keyCode === KEY_CODE_SPACE
      ) {
        this.sortByField(field);
      }
    },

    paginateItems(items) {
      const startIndex = (this.localCurrentPage - 1) * this.localPerPage;
      const endIndex = startIndex + this.localPerPage;

      return items.slice(startIndex, endIndex);
    },

    rowKey(item, index) {
      if (this.uniqueRowKey) {
        return `row-${item.id || index}-${generateId()}`;
      } else {
        return `row-${item.id || index}`;
      }
    },

    searchItems(items) {
      if (!this.localSearch) {
        return items;
      }

      // Get all keys and subkeys from the first item
      const keys = getObjectKeysAndSubKeys(items[0]);

      // Create a new Fuse instance, which does a fuzzy search
      const fuse = new Fuse(items, {
        keys: keys,
        threshold: 0.3,
        findAllMatches: true,
      });

      return fuse.search(this.localSearch).map((result) => result.item);
    },

    selectAll() {
      if (this.allSelected) {
        this.selectedItems = [];
      } else {
        this.selectedItems = [...this.filteredItems];
      }

      this.$emit("selected", this.selectedItems);
    },

    selectItem(item) {
      if (this.selectedItems.includes(item)) {
        this.selectedItems = this.selectedItems.filter(
          (selectedItem) => selectedItem !== item
        );
      } else {
        this.selectedItems.push(item);
      }

      this.$emit("selected", this.selectedItems);
    },

    sortByField(field) {
      if (!field.sortable) {
        return;
      }

      if (this.localSortBy === field.key) {
        if (this.localSortDirection === "asc") {
          this.localSortDirection = "desc";
        } else {
          this.localSortBy = null;
          this.localSortDirection = null;
        }
      } else {
        this.localSortBy = field.key;
        this.localSortDirection = "asc";
      }
    },

    sortItems(items) {
      const { localSortBy, localSortDirection } = this;

      if (localSortBy) {
        const sortedItems = [...items];
        const field = this.computedFields.find(
          (field) => field.key === localSortBy
        );
        const sortByFormatted = field.sortByFormatted || false;
        const formatter = field.formatter || null;

        sortedItems.sort((a, b) => {
          let aValue = a[localSortBy];
          let bValue = b[localSortBy];

          // If sortByFormatted is true, use the formatter function to get the
          // value to sort by
          if (sortByFormatted && formatter) {
            aValue = formatter(aValue, localSortBy, a);
            bValue = formatter(bValue, localSortBy, b);
          }

          // Sort "null" or empty values last
          if (aValue === null || aValue === "") {
            return 1;
          }
          if (bValue === null || bValue === "") {
            return -1;
          }

          // Use the compareStrings method if both values are strings
          if (typeof aValue === "string" && typeof bValue === "string") {
            if (localSortDirection === "desc") {
              return this.compareStrings(bValue, aValue);
            } else {
              return this.compareStrings(aValue, bValue);
            }
          }

          // Otherwise, use the default sort method
          if (localSortDirection === "desc") {
            return bValue - aValue;
          } else {
            return aValue - bValue;
          }
        });

        return sortedItems;
      }

      return items;
    },
  },
};
</script>
