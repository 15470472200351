<template>
  <div class="page-break-avoid mb-3">
    <div
      v-if="
        mode === 'template' &&
        !element.description &&
        !element.financial_configuration
      "
      class="mb-3 p-4 border rounded"
    >
      <h5>{{ $t("models.report_component.titles.graph") }}</h5>

      <be-skeleton-graph />
    </div>

    <financials-visual
      v-else-if="financialsInitialized"
      :configuration="element.description"
      :configuration-object="element.financial_configuration"
      :force-origin="elementOrigin(element, report)"
      :force-end-date-string="reportEndDateString(report)"
      :forced-financials-uuid="reportFinancialsUuid(report)"
      :force-show="forceShow"
      @financials-initialized="handleFinancialsInitialized"
    />

    <div
      v-else-if="!initializingFinancials"
      class="d-flex flex-column border rounded text-center p-3 mb-3"
    >
      <h5>
        <i class="far fa-chart-column mr-2" />
        {{ $t("models.report_component.tooltips.financials_not_loaded") }}
      </h5>

      <be-skeleton-graph />
    </div>

    <comments-viewer v-if="element.comments" :comments="element.comments" />
  </div>
</template>

<script>
import CommentsViewer from "./CommentsViewer.vue";
import ReportMixin from "@/mixins/reports";
import FinancialsVisual from "@/components/financials/FinancialsVisual.vue";
import { EventBus } from "@/event-bus";

export default {
  components: {
    CommentsViewer,
    FinancialsVisual,
  },

  mixins: [ReportMixin],

  props: {
    element: {
      type: Object,
      required: true,
    },

    report: {
      type: Object,
      required: true,
    },

    forceShow: {
      type: Boolean,
      required: false,
      default: false,
    },

    mode: {
      type: String,
      required: false,
      default: "report",
    },
  },

  computed: {
    isCorporateGroupReport() {
      return (
        this.report.report_type === "corporate_group" ||
        this.report.corporate_group_data
      );
    },
  },

  mounted() {
    if (!this.element.description) {
      EventBus.emit("report-component-initialized", this.element);
    }
  },

  methods: {
    handleFinancialsInitialized() {
      EventBus.emit("report-component-initialized", this.element);
    },
  },
};
</script>
