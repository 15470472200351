<template>
  <component
    :is="showNativeSelect ? 'native-select' : 'custom-select'"
    ref="select"
    v-bind="computedAttrs"
    :model-value="localModelValue"
    :options="computedOptions"
    @update:model-value="onChange"
    @search="onSearch"
  >
    <template
      v-for="slotName in ['option', 'selected', 'tag']"
      :key="slotName"
      v-slot:[slotName]="slotProps"
    >
      <slot :name="slotName" v-bind="slotProps">
        <custom-select-preset-templates
          v-if="localTemplate"
          :option="slotProps.option"
          :template="localTemplate"
        />
      </slot>
    </template>

    <template v-if="!showNativeSelect" #dropdown-footer>
      <slot name="dropdown-footer" />
    </template>
  </component>
</template>

<script>
import CustomSelect from "@/components/shared/be_form_select/CustomSelect.vue";
import CustomSelectPresetTemplates from "@/components/shared/be_form_select/CustomSelectPresetTemplates.vue";
import NativeSelect from "@/components/shared/be_form_select/NativeSelect.vue";
import { getPresetOptions } from "@/components/shared/be_form_select/preset-options";
import { formatOptions } from "@/components/shared/be_form_select/utils";
import FormStateMixin from "@/mixins/forms/form-state.js";
import isMobile from "is-mobile";
import { generateId } from "@/utils/id";
import { computed } from "vue";

export default {
  name: "BeFormSelect",

  inheritAttrs: false,

  components: {
    CustomSelect,
    CustomSelectPresetTemplates,
    NativeSelect,
  },

  mixins: [FormStateMixin],

  provide() {
    return {
      asyncSearch: computed(() => this.asyncSearch),
      asyncSearchDebounce: computed(() => this.asyncSearchDebounce),
      asyncSearchFormatter: computed(() => this.asyncSearchFormatter),
      isAsyncSearching: computed(() => this.isAsyncSearching),
      optionLabel: computed(() => this.optionLabel),
      optionValue: computed(() => this.optionValue),
      searchPlaceholder: computed(() => this.searchPlaceholder),
      template: computed(() => this.localTemplate),
    };
  },

  props: {
    asyncSearch: {
      type: String,
      required: false,
      default: null,
    },

    asyncSearchDebounce: {
      type: Number,
      required: false,
      default: 300,
    },

    asyncSearchFormatter: {
      type: Function,
      required: false,
      default: (option) => option,
    },

    confirmChange: {
      type: Boolean,
      required: false,
      default: null,
    },

    confirmChangeTitle: {
      type: String,
      required: false,
      default: "",
    },

    confirmChangeText: {
      type: String,
      required: false,
      default: "",
    },

    id: {
      type: String,
      required: false,
      default: () => generateId("be-form-select"),
    },

    includeBlankOption: {
      type: [Boolean, String],
      required: false,
      default: null,
    },

    modelValue: {
      type: [Object, Array, String, Number],
      required: false,
      default: null,
    },

    multiple: {
      type: Boolean,
      required: false,
      default: null,
    },

    native: {
      type: Boolean,
      required: false,
      default: null,
    },

    options: {
      type: Array,
      required: false,
      default: () => [],
    },

    optionLabel: {
      type: String,
      required: false,
      default: "label",
    },

    optionValue: {
      type: String,
      required: false,
      default: "value",
    },

    presetOptions: {
      type: String,
      required: false,
      default: null,
    },

    required: {
      type: Boolean,
      required: false,
      default: false,
    },

    searchPlaceholder: {
      type: String,
      required: false,
      default: null,
    },

    template: {
      type: String,
      required: false,
      default: null,
    },
  },

  emits: ["change", "close", "open", "search", "update:modelValue"],

  data() {
    return {
      asyncSearchOptions: [],
      isAsyncSearching: false,
      localModelValue: this.modelValue,
      localTemplate: this.template,
    };
  },

  computed: {
    computedAttrs() {
      return {
        ...this.$attrs,
        id: this.id,
        multiple: this.multiple,
        optionLabel: this.optionLabel,
        optionValue: this.optionValue,
      };
    },

    computedOptions() {
      let options = [];

      if (this.asyncSearch) {
        // If asyncSearch is provided, use the async search options
        options = formatOptions(
          this.asyncSearchOptions,
          this.optionLabel,
          this.optionValue
        );
      } else if (this.presetOptions) {
        // If presetOptions is provided, use the preset options
        options = getPresetOptions(this.presetOptions);
      } else {
        options = formatOptions(
          this.options,
          this.optionLabel,
          this.optionValue
        );
      }

      // Add blank option if:
      // - includeBlankOption is true or the select is not required
      // - multiple is false
      // - options is not empty
      if (
        (this.includeBlankOption || !this.computedRequired) &&
        !this.multiple &&
        options.length > 0
      ) {
        options.unshift({
          [this.optionLabel]:
            this.includeBlankOption === true ? "" : this.includeBlankOption,

          [this.optionValue]: null,
        });
      }

      return options;
    },

    computedRequired() {
      if (this.required) {
        return this.required;
      } else if (this.isGroup && this.beGroup) {
        return this.beGroup.localRequired;
      } else {
        return false;
      }
    },

    isGroup() {
      // Check if the parent is a `BeFormGroup` component
      if (this.$parent && this.$parent.$options.name === "BeFormGroup") {
        return true;
      } else if (
        // Check if the parent's parent is a `BeFormGroup` component
        this.$parent.$parent &&
        this.$parent.$parent.$options.name === "BeFormGroup"
      ) {
        return true;
      } else {
        return false;
      }
    },

    beGroup() {
      if (!this.isGroup) {
        return null;
      }

      // Check if the parent is a `BeFormGroup` component
      if (this.$parent && this.$parent.$options.name === "BeFormGroup") {
        return this.$parent;
      } else if (
        // Check if the parent's parent is a `BeFormGroup` component
        this.$parent.$parent &&
        this.$parent.$parent.$options.name === "BeFormGroup"
      ) {
        return this.$parent.$parent;
      }

      return null;
    },

    showNativeSelect() {
      return (this.native || isMobile()) && !this.multiple && !this.asyncSearch;
    },
  },

  watch: {
    modelValue: {
      handler(newValue) {
        this.localModelValue = newValue;
      },

      deep: true,
    },
  },

  mounted() {
    // If presetOptions is provided, set the template to the corresponding template
    if (this.presetOptions) {
      this.setTemplate();

      // If modelValue is not set and the field is required, set the default value
      this.$nextTick(() => {
        if (!this.modelValue && this.computedRequired) {
          this.emitDefaultValue();
        }
      });
    }
  },

  methods: {
    emitDefaultValue() {
      const defaultValues = {
        countries: "SE",
        currencies: "SEK",
        locales: "sv",
      };

      if (defaultValues[this.presetOptions]) {
        this.localModelValue = defaultValues[this.presetOptions];
        this.$emit("update:modelValue", defaultValues[this.presetOptions]);
      }
    },

    async onChange(newValue) {
      if (this.confirmChange) {
        const isConfirmed = await this.promptConfirm({
          confirmButtonText: this.$t("buttons.titles.confirm"),
          title: this.confirmChangeTitle,
          description: this.confirmChangeText,
        });

        if (!isConfirmed) {
          if (!this.showNativeSelect) {
            this.$refs.select.reset();
          }

          return;
        }
      }

      this.$emit("update:modelValue", newValue);
      this.$emit("change", newValue);
    },

    async onSearch(searchQuery) {
      this.$emit("search", searchQuery);

      // If URL is provided, fetch the async search options
      if (this.asyncSearch) {
        this.isAsyncSearching = true;

        try {
          const { data } = await axios.get(this.asyncSearch, {
            params: { q: searchQuery },
          });

          this.asyncSearchOptions = data.map(this.asyncSearchFormatter);
        } catch (error) {
          this.handleError(error);
          this.asyncSearchOptions = [];
        } finally {
          this.isAsyncSearching = false;
        }
      }
    },

    setTemplate() {
      const templates = {
        countries: "country",
        currencies: "currency",
        locales: "locale",
      };

      if (templates[this.presetOptions]) {
        this.localTemplate = templates[this.presetOptions];
      }
    },
  },
};
</script>
