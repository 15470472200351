<template>
  <div :class="computedClasses">
    <input
      :id="id"
      ref="input"
      v-model="computedLocalChecked"
      :name="name || null"
      :class="computedInputClasses"
      type="checkbox"
      :disabled="disabled || null"
      :required="isRequired || null"
      :form="form || null"
      :aria-label="ariaLabel"
      :aria-labelledby="ariaLabelledby"
      :aria-required="required || undefined"
      :tabindex="tabindex"
      :autocomplete="autocomplete"
    />

    <label :for="id" :class="computedLabelClasses">
      <slot />

      <slot name="description">
        <small v-if="description" class="d-block text-muted">
          {{ description }}
        </small>
      </slot>
    </label>
  </div>
</template>

<script>
import formStateMixin from "@/mixins/forms/form-state";
import { generateId } from "@/utils/id";

export default {
  name: "BeFormCheckbox",

  mixins: [formStateMixin],

  props: {
    ariaLabel: {
      type: String,
      default: null,
    },

    ariaLabelledby: {
      type: String,
      default: null,
    },

    autocomplete: {
      type: String,
      default: null,
    },

    autofocus: {
      type: Boolean,
      default: false,
    },

    checked: {
      type: undefined,
      default: false,
    },

    checkedValue: {
      type: undefined,
      default: true,
    },

    description: {
      type: String,
      default: null,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    form: {
      type: String,
      default: null,
    },

    id: {
      type: String,
      required: false,
      default: () => generateId("be-form-checkbox"),
    },

    inline: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      required: false,
      default: false,
    },

    modelValue: {
      type: undefined,
      default: false,
    },

    name: {
      type: String,
      required: false,
      default: null,
    },

    required: {
      type: Boolean,
      default: false,
    },

    size: {
      type: String,
      default: null,
    },

    tabindex: {
      type: [Number, String],
      default: null,
    },

    uncheckedValue: {
      type: undefined,
      default: false,
    },
  },

  emits: ["input", "change", "update:modelValue"],

  data() {
    return {
      localChecked: this.modelValue || this.checked,
    };
  },

  computed: {
    computedLocalChecked: {
      get() {
        return this.localChecked === this.checkedValue;
      },

      set() {
        const updateValue =
          this.localChecked === this.checkedValue
            ? this.uncheckedValue
            : this.checkedValue;

        this.localChecked = updateValue;
        this.$emit("input", updateValue);
        this.$emit("update:modelValue", updateValue);

        this.$nextTick(() => {
          this.$emit("change", this.localChecked);
        });
      },
    },

    isRequired() {
      // Required only works when a name is provided for the input(s)
      return this.name && this.required;
    },

    computedClasses() {
      const { loading, inline, size } = this;

      return [
        "custom-control",
        "custom-checkbox",
        {
          "custom-control-inline": inline,
          // Temporary until Bootstrap supports sizing on custom checkboxes
          [`be-custom-control-${size}`]: size,
          loading,
        },
      ];
    },

    computedInputClasses() {
      return ["custom-control-input", this.stateClass];
    },

    computedLabelClasses() {
      return ["custom-control-label"];
    },
  },

  watch: {
    modelValue: {
      handler(checked) {
        if (
          (checked === this.checkedValue || checked === true) &&
          this.computedLocalChecked !== true
        ) {
          this.computedLocalChecked = true;
        } else if (
          (checked === this.uncheckedValue || checked === false) &&
          this.computedLocalChecked !== false
        ) {
          this.computedLocalChecked = false;
        }
      },
    },

    checked: {
      handler(newValue) {
        if (newValue !== this.computedLocalChecked) {
          this.localChecked = newValue;
        }
      },

      deep: true,
    },
  },

  mounted() {
    if (this.autofocus) {
      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    }
  },
};
</script>
