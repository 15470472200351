/* eslint-disable ext/lines-between-object-properties */

import { itemActive } from "@/utils/menu";

export default {
  name: "User Settings",
  header: {
    title: (ctx) => ctx.$currentUser.name,
    subtitle: (ctx) => ctx.$t("views.layouts.sidebar.user_settings.subtitle"),
    avatar: (ctx) => ctx.$currentUser,
  },
  links: [
    {
      text: (ctx) => ctx.$t("application.my_profile"),
      url: "/users/profile",
      icon: "fa-user",
      active: (ctx) =>
        itemActive({
          context: ctx,
          controllers: ["users/profiles"],
        }),
    },
    {
      text: (ctx) => ctx.$t("application.my_organizations"),
      url: "/users/organizations",
      icon: "fa-grid",
      active: (ctx) =>
        itemActive({
          context: ctx,
          controllers: ["users/organizations"],
        }),
    },
    {
      text: (ctx) =>
        ctx.platformEnabled("password_change") &&
        ctx.$platform.idp_login !== "fortnox"
          ? ctx.$t("models.user.email_password")
          : ctx.$t("activerecord.attributes.user.email"),
      url: "/users/settings",
      icon: "fa-cogs",
      enabled: (ctx) => ctx.platformEnabled("email_change"),
      active: (ctx) =>
        itemActive({
          context: ctx,
          controllers: ["users/settings"],
        }),
    },
    {
      name: "mfa",
      text: (ctx) => ctx.$t("application.security"),
      url: "/users/mfa",
      icon: "fa-key",
      enabled: (ctx) => ctx.platformEnabled("mfa"),
      active: (ctx) =>
        itemActive({
          context: ctx,
          controllers: ["users/mfa"],
        }),
    },
    {
      name: "sessions",
      text: (ctx) => ctx.$t("application.sessions"),
      url: "/users/sessions",
      icon: "fa-laptop-mobile",
      enabled: (ctx) => ctx.flipperFlag("sessions_list"),
      active: (ctx) =>
        itemActive({
          context: ctx,
          controllers: ["users/sessions"],
        }),
    },
    {
      name: "authorized_applications",
      text: (ctx) => ctx.$t("application.oauth_applications"),
      url: "/users/authorized_applications",
      icon: "fa-mobile",
      enabled: (ctx) => ctx.platformEnabled("external_applications"),
      active: (ctx) =>
        itemActive({
          context: ctx,
          controllers: ["users/authorized_applications"],
        }),
    },
    {
      name: "gdpr",
      text: (ctx) => ctx.$t("application.gdpr"),
      url: "/users/gdpr",
      icon: "fa-balance-scale",
      enabled: (ctx) => ctx.platformEnabled("gdpr"),
      active: (ctx) =>
        itemActive({
          context: ctx,
          controllers: ["users/gdpr"],
        }),
    },
    {
      name: "riksbyggen",
      text: "Riksbyggen",
      url: "/users/riksbyggen",
      icon: "fa-home",
      enabled: (ctx) => ctx.$platform.idp_login === "riksb",
      active: (ctx) =>
        itemActive({
          context: ctx,
          controllers: ["users/riksbyggen"],
        }),
    },
    {
      name: "terms_of_use",
      text: (ctx) => ctx.$t("application.terms_of_use"),
      url: "/users/terms_of_use",
      icon: "fa-file-contract",
      enabled: (ctx) => ctx.platformEnabled("terms_of_use"),
      active: (ctx) =>
        itemActive({
          context: ctx,
          controllers: ["users/terms_of_uses"],
        }),
    },
  ],
};
