<template>
  <div
    v-be-visible="visibilityChanged"
    :class="{
      card: card,
      'vb-loading': !rendered,
      'vb-loading-auto-height': autoHeight,
    }"
  >
    <div
      v-show="isVisible"
      :id="uid"
      ref="visual-component"
      :class="{ 'card-body': card }"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";

const companyHelper = createNamespacedHelpers("company");

export default {
  props: {
    configuration: {
      type: String,
      required: false,
      default: "",
    },

    configurationObject: {
      type: Object,
      required: false,
      default: () => {},
    },

    forceOrigin: {
      type: String,
      required: false,
      default: null,
    },

    forcedFinancialsUuid: {
      type: String,
      required: false,
      default: null,
    },

    forceEndDateString: {
      type: String,
      required: false,
      default: null,
    },

    card: {
      type: Boolean,
      required: false,
      default: false,
    },

    forceShow: {
      type: Boolean,
      required: false,
      default: false,
    },

    autoHeight: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ["financials-initialized"],

  data() {
    return {
      rendered: false,
      isVisible: this.forceShow,
    };
  },

  computed: {
    ...companyHelper.mapGetters(["hasInitializedFinancials", "getFinancials"]),

    uid() {
      return `visual-component-${this.$.uid}`;
    },

    hasJsonConfiguration() {
      return (
        this.configurationObject &&
        this.configurationObject?.data &&
        this.configurationObject?.version
      );
    },
  },

  watch: {
    configuration() {
      this.rendered = false;

      this.initializeComponent();
    },

    forceOrigin() {
      this.rendered = false;

      this.initializeComponent();
    },

    $activeFinancialsUuid() {
      this.rendered = false;

      this.initializeComponent();
    },

    forcedFinancialsUuid() {
      this.rendered = false;

      this.initializeComponent();
    },

    // Initialize component if financials.initialized is true
    getFinancials(value) {
      if (value.initialized && !this.rendered) {
        this.initializeComponent();
      }
    },
  },

  mounted() {
    if (this.hasInitializedFinancials) {
      this.initializeComponent();
    }
  },

  methods: {
    visibilityChanged(isVisible) {
      if (isVisible) {
        this.isVisible = true;
        this.initializeComponent();
      }
    },

    initializeComponent() {
      if (!this.isVisible) {
        return;
      }

      if (
        this.rendered ||
        !this.hasInitializedFinancials ||
        (!this.configuration && !this.hasJsonConfiguration)
      ) {
        return;
      }

      // Clear the contents of the element
      if (this.$refs["visual-component"]) {
        this.$refs["visual-component"].innerHTML = "";
      }

      // Check if origin has been selected and added to financials.origin
      const selectedOrigin =
        this.forceOrigin || this.getFinancials?.origin || null;

      const numberScale = this.getFinancials?.numberScale || null;

      let endDate = null;
      let endDateString =
        this.forceEndDateString || this.company.financials.endDateString;
      if (endDateString) {
        // use Date.parse to always get local timezone
        endDate = Date.parse(endDateString);
      } else {
        endDate = this.company.financials.endDate || null;
      }

      const config = {
        showConfig: false,
        compactConfig: false,
        controllerType: false,
        showExportButton: true,
        endDate: endDate,
        configUUID: this.configuration,
        configuration: null,
        origin: selectedOrigin,
        numberScale: numberScale,

        renderedCallback: () => {
          this.rendered = true;
          this.$emit("financials-initialized");
        },
      };

      if (this.hasJsonConfiguration) {
        config.configUUID = undefined;
        config.configuration = this.configurationObject.data.config;
      }

      window
        .wzGetController()
        .addVisualToConfig(
          `#${this.uid}`,
          this.forcedFinancialsUuid || this.$activeFinancialsUuid,
          config
        );
    },
  },
};
</script>
