<template>
  <li v-if="hasVisibleChildren" role="presentation">
    <be-dropdown-divider v-if="showDivider" />

    <component
      :is="headerTag"
      v-if="showHeader"
      :id="headerId"
      ref="header"
      :class="headerClasses"
      :role="headerTag === 'header' ? 'heading' : null"
    >
      <slot name="header">
        {{ header }}
      </slot>
    </component>

    <ul
      class="list-unstyled"
      role="group"
      :aria-describedby="computedAriaDescribedBy"
    >
      <slot />
    </ul>
  </li>
</template>

<script>
export default {
  name: "BeDropdownGroup",

  props: {
    ariaDescribedBy: {
      type: String,
      required: false,
      default: null,
    },

    header: {
      type: String,
      required: false,
      default: null,
    },

    headerClass: {
      type: [Array, Object, String],
      required: false,
      default: null,
    },

    headerTag: {
      type: String,
      required: false,
      default: "header",
    },

    headerVariant: {
      type: String,
      required: false,
      default: null,
    },

    id: {
      type: String,
      required: false,
      default: null,
    },

    noDivider: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      showDivider: false,
    };
  },

  computed: {
    headerId() {
      const { id } = this;

      return id ? `${id}-dropdown-header` : null;
    },

    headerClasses() {
      const { headerClass, headerVariant } = this;

      return [
        "dropdown-header",
        headerClass,
        {
          [`text-${headerVariant}`]: headerVariant,
        },
      ];
    },

    computedAriaDescribedBy() {
      return (
        [this.headerId, this.ariaDescribedBy]
          .filter(Boolean)
          .join(" ")
          .trim() || null
      );
    },

    hasVisibleChildren() {
      return (
        this.$slots.default &&
        this.$slots.default().length &&
        this.$slots
          .default()
          .some((vnode) => typeof vnode.children !== "string")
      );
    },

    showHeader() {
      return this.header || this.$slots.header;
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.maybeShowDivider();
    });
  },

  methods: {
    hasPreviousSiblings() {
      const parent = this.$parent.$el.children[1];
      const children = parent.children;

      return Array.from(children).indexOf(this.$el) > 0;
    },

    maybeShowDivider() {
      const hasPreviousSiblings = this.hasPreviousSiblings();

      if (hasPreviousSiblings && !this.noDivider) {
        this.showDivider = true;
      } else {
        this.showDivider = false;
      }
    },
  },
};
</script>
