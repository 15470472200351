<template>
  <select
    v-bind="computedAttrs"
    ref="select"
    :class="computedClasses"
    @change="onChange"
  >
    <template v-for="(option, index) in options" :key="index">
      <native-select-option-group
        v-if="Array.isArray(option.options)"
        :label="option[optionLabel]"
        :options="option.options"
      />

      <native-select-option
        v-else
        :value="option[optionValue]"
        :disabled="option.disabled"
      >
        {{ option[optionLabel] }}
      </native-select-option>
    </template>
  </select>
</template>

<script>
import NativeSelectOption from "@/components/shared/be_form_select/NativeSelectOption.vue";
import NativeSelectOptionGroup from "@/components/shared/be_form_select/NativeSelectOptionGroup.vue";
import FormStateMixin from "@/mixins/forms/form-state.js";

export default {
  name: "NativeSelect",

  components: {
    NativeSelectOption,
    NativeSelectOptionGroup,
  },

  mixins: [FormStateMixin],

  props: {
    ariaInvalid: {
      type: [Boolean, String],
      required: false,
      default: false,
    },

    autofocus: {
      type: Boolean,
      required: false,
      default: false,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    id: {
      type: String,
      required: true,
    },

    modelValue: {
      type: undefined,
      required: false,
      default: null,
    },

    name: {
      type: String,
      required: false,
      default: "",
    },

    options: {
      type: Array,
      required: false,
      default: () => [],
    },

    optionLabel: {
      type: String,
      required: false,
      default: "label",
    },

    optionValue: {
      type: String,
      required: false,
      default: "value",
    },

    required: {
      type: Boolean,
      required: false,
      default: false,
    },

    size: {
      type: String,
      required: false,
      default: null,

      validator: (value) => ["sm", "lg"].includes(value),
    },
  },

  emits: ["update:modelValue"],

  computed: {
    computedAttrs() {
      return {
        "aria-invalid": this.computedAriaInvalid || null,
        "aria-required": this.required ? "true" : null,
        disabled: this.disabled || null,
        id: this.id,
        name: this.name,
        required: this.required || null,
        value: this.modelValue,
      };
    },

    computedClasses() {
      return [
        "custom-select",
        this.stateClass,
        { [`custom-select-${this.size}`]: this.size },
      ];
    },
  },

  mounted() {
    if (this.autofocus) {
      this.$nextTick(() => {
        this.$refs.select.focus();
      });
    }
  },

  methods: {
    onChange(event) {
      this.$emit("update:modelValue", event.target.value);
    },
  },
};
</script>
