<template>
  <div class="be-form-custom-select-dropdown" role="listbox">
    <slot v-if="searchEnabled" name="search" />

    <custom-select-dropdown-options
      @highlight-option="highlightOption"
      @option-click="onOptionClick"
    >
      <template #option="optionScope">
        <slot name="option" v-bind="optionScope" />
      </template>
    </custom-select-dropdown-options>

    <div
      v-if="filteredOptions.length === 0 && !asyncSearch"
      class="text-center text-muted p-3"
    >
      <template v-if="searchQuery">
        {{
          emptySearchResultsText ||
          $t("components.shared.be_form_select.no_search_results")
        }}
      </template>

      <template v-else>
        {{
          emptyOptionsText ||
          $t("components.shared.be_form_select.no_selectable_options")
        }}
      </template>
    </div>

    <slot name="dropdown-footer" />
  </div>
</template>

<script>
import CustomSelectDropdownOptions from "@/components/shared/be_form_select/CustomSelectDropdownOptions.vue";

export default {
  components: {
    CustomSelectDropdownOptions,
  },

  inject: [
    "asyncSearch",
    "filteredOptions",
    "emptySearchResultsText",
    "emptyOptionsText",
    "searchEnabled",
    "searchQuery",
  ],

  emits: ["highlight-option", "option-click"],

  methods: {
    highlightOption(index) {
      this.$emit("highlight-option", index);
    },

    onOptionClick(option) {
      this.$emit("option-click", option);
    },
  },
};
</script>
