/* eslint-disable ext/lines-between-object-properties */

import { itemActive } from "@/utils/menu";

export default {
  name: "Board Room",
  header: {
    title: (ctx) => ctx.$currentCompany.title,
    subtitle: (ctx) => {
      return ctx.$t("views.layouts.sidebar.board_room.subtitle", {
        room_type: ctx.$platform.company_room_name,
      });
    },
    avatar: (ctx) => {
      return {
        name: ctx.$currentCompany.title,
        image_url: ctx.$currentCompany.logo_url,
      };
    },
  },
  components: [
    {
      name: "UpcomingMeetingsWrapper",
      props: {
        collapsed: (ctx) => ctx.collapsed,
      },
    },
  ],
  links: [
    // Dashboard
    {
      text: (ctx) => ctx.$t("components.nav.board_room.dashboard"),
      url: "/",
      icon: "fa-browser",
      active: (ctx) =>
        itemActive({
          context: ctx,
          controllers: ["companies/companies"],
          actions: ["show"],
        }),
    },

    // Meetings
    {
      text: (ctx) => ctx.$t("activerecord.models.meeting.other"),
      url: "#",
      icon: "fa-screen-users",
      active: (ctx) =>
        itemActive({
          context: ctx,
          controllers: [
            "companies/meetings/materials",
            "companies/meetings/minutes",
            "companies/meetings/agenda",
            "companies/meetings/minutes/reviews",
          ],
        }),

      children: [
        {
          text: (ctx) => ctx.$t("models.meeting.all_meetings"),
          url: "/meetings",
          enabled: (ctx) => ctx.platformEnabledAndSubscribed("board_portal"),
          active: (ctx) =>
            itemActive({
              context: ctx,
              controllers: [
                "companies/meetings",
                "companies/meetings/archives",
              ],
              actions: ["index", "show"],
            }),
        },
        {
          text: (ctx) => ctx.$t("models.meeting.plan_meeting"),
          url: "/meetings/new",
          enabled: (ctx) =>
            ctx.platformEnabledAndSubscribed("board_portal") &&
            ctx.policies.can_create_meeting,
          active: (ctx) =>
            itemActive({
              context: ctx,
              controllers: ["companies/meetings"],
              actions: ["new"],
            }),
        },
        {
          text: (ctx) => ctx.$t("models.annual_cycle.board_year"),
          url: "/annual_cycle",
          enabled: (ctx) =>
            ctx.platformEnabledAndSubscribed("board_portal") &&
            ctx.platformEnabled("annual_cycle") &&
            ctx.policies.can_show_annual_cycle,
          active: (ctx) =>
            itemActive({
              context: ctx,
              controllers: ["companies/annual_cycles"],
            }),
        },
        {
          text: (ctx) => ctx.$t("activerecord.models.agenda_template.other"),
          url: "/meetings/agenda_templates",
          enabled: (ctx) =>
            ctx.platformEnabledAndSubscribed("board_portal") &&
            ctx.policies.can_create_meeting,
          active: (ctx) =>
            itemActive({
              context: ctx,
              controllers: ["companies/meetings/agenda_templates"],
            }),
        },
        {
          text: (ctx) => ctx.$t("activerecord.models.phrase.other"),
          url: "/meetings/phrases",
          enabled: (ctx) =>
            ctx.platformEnabledAndSubscribed("board_portal") &&
            ctx.policies.can_index_phrases,
          active: (ctx) =>
            itemActive({
              context: ctx,
              controllers: ["companies/meetings/phrases"],
              actions: ["index"],
            }),
        },
      ],
    },

    // Tasks
    {
      text: (ctx) => ctx.$t("activerecord.models.task.other"),
      url: "/tasks",
      icon: "fa-tasks",
      enabled: (ctx) => ctx.platformEnabled("tasks"),
      active: (ctx) =>
        itemActive({
          context: ctx,
          controllers: ["companies/tasks"],
        }),
    },

    // Decisions
    {
      text: (ctx) => ctx.$t("components.nav.board_room.decision_followup"),
      url: "/decisions",
      icon: "fa-gavel",
      enabled: (ctx) =>
        ctx.platformEnabledAndSubscribed("board_portal") &&
        ctx.platformEnabled("decisions"),
      active: (ctx) =>
        itemActive({
          context: ctx,
          controllers: ["companies/decisions"],
        }),
    },

    // Tickets
    {
      text: (ctx) => ctx.$t("activerecord.models.ticket.other"),
      icon: "fa-inbox",
      url: "/tickets",
      active: (ctx) =>
        itemActive({
          context: ctx,
          controllers: ["companies/tickets"],
        }),
      enabled: (ctx) =>
        ctx.$currentCompany.tickets_visible &&
        ctx.policies.can_index_tickets &&
        ctx.flipperFlag("client_manager_assignments"),
    },

    // Inquiries without Kleer Payments
    {
      text: (ctx) => ctx.$t("activerecord.models.inquiry.other"),
      url: "/inquiries",
      icon: "fa-seal-question",
      enabled: (ctx) => {
        return (
          ctx.platformEnabled("inquiries") &&
          ctx.policies.can_index_inquiry &&
          !ctx.$currentCompany.pe_accounting_payments_enabled
        );
      },
      active: (ctx) =>
        itemActive({
          context: ctx,
          controllers: ["companies/inquiries"],
        }),
    },

    // Inquiries with Kleer Payments
    {
      text: (ctx) => ctx.$t("activerecord.models.inquiry.other"),
      url: "#",
      icon: "fa-seal-question",

      children: [
        {
          text: (ctx) => ctx.$t("activerecord.models.inquiry.all"),
          url: "/inquiries",
          enabled: (ctx) => {
            return (
              ctx.platformEnabled("inquiries") &&
              ctx.policies.can_index_inquiry &&
              ctx.$currentCompany.pe_accounting_payments_enabled
            );
          },
          active: (ctx) =>
            itemActive({
              context: ctx,
              controllers: ["companies/inquiries"],
            }),
        },
        {
          text: (ctx) =>
            ctx.$t("views.companies.inquiry_payments.index.payments"),
          url: "/inquiry_payments",
          enabled: (ctx) => {
            return (
              ctx.platformEnabled("inquiries") &&
              ctx.policies.can_index_inquiry &&
              ctx.$currentCompany.pe_accounting_payments_enabled
            );
          },
          active: (ctx) =>
            itemActive({
              context: ctx,
              controllers: ["companies/inquiry_payments"],
            }),
        },
        {
          text: (ctx) => ctx.$t("activerecord.models.inquiry_import.other"),
          url: "/inquiry_imports",
          enabled: (ctx) => {
            return (
              ctx.platformEnabled("inquiries") &&
              ctx.policies.can_index_inquiry &&
              ctx.$currentCompany.pe_accounting_payments_enabled &&
              ctx.platformEnabled("inquiry_import")
            );
          },
          active: (ctx) =>
            itemActive({
              context: ctx,
              controllers: ["companies/inquiry_imports"],
            }),
        },
        {
          text: (ctx) => ctx.$t("views.companies.dimensions.index.title"),
          url: "/dimensions",
          enabled: (ctx) => {
            return (
              ctx.platformEnabled("inquiries") &&
              ctx.policies.can_index_dimensions &&
              ctx.$currentCompany.pe_accounting_payments_enabled
            );
          },
          active: (ctx) =>
            itemActive({
              context: ctx,
              controllers: ["companies/dimensions"],
            }),
        },
      ],
    },

    // Projects
    {
      text: (ctx) => ctx.$t("activerecord.models.project.other"),
      url: "/projects",
      icon: "fa-chart-network",
      enabled: (ctx) => ctx.platformEnabledAndSubscribed("projects"),
      active: (ctx) =>
        itemActive({
          context: ctx,
          controllers: ["companies/projects"],
        }),
    },

    // Contacts
    {
      text: (ctx) => ctx.$t("activerecord.models.contact.other"),
      url: "/contacts",
      icon: "fa-book",
      enabled: (ctx) =>
        ctx.platformEnabled("contacts") && ctx.policies.can_index_contacts,
      active: (ctx) =>
        itemActive({
          context: ctx,
          controllers: ["companies/contacts"],
        }),
    },

    // Documents
    {
      text: (ctx) => ctx.$t("activerecord.models.document.other"),
      url: "#",
      icon: "fa-files",
      badge: {
        component: "SidebarDocumentsBadge",
        componentProps: {
          css: (ctx) => (ctx.collapsed ? "position-absolute" : "ml-auto mr-1"),
          mode: "total",
        },
      },
      promoteSingleChildText: true,

      children: [
        {
          text: (ctx) => ctx.$t("models.document.all_documents"),
          url: "/documents",
          enabled: (ctx) => ctx.platformEnabled("documents"),
          active: (ctx) =>
            itemActive({
              context: ctx,
              controllers: ["companies/documents"],
            }),
        },
        {
          text: (ctx) => ctx.$t("activerecord.models.signature.other"),
          url: "/documents/signatures",
          active: (ctx) =>
            itemActive({
              context: ctx,
              controllers: ["companies/documents/signatures"],
            }),
          badge: {
            component: "SidebarDocumentsBadge",
            componentProps: {
              mode: "pending_signatures",
            },
          },
          enabled: (ctx) =>
            ctx.platformEnabledAndSubscribed("e-signature") &&
            ctx.platformEnabled("documents"),
        },
        {
          text: (ctx) => ctx.$t("activerecord.models.compliance.one"),
          url: "/documents/compliances",
          active: (ctx) =>
            itemActive({
              context: ctx,
              controllers: ["companies/documents/compliances"],
            }),
          enabled: (ctx) =>
            ctx.platformEnabled("compliance") &&
            ctx.platformEnabled("documents"),
        },
        {
          text: (ctx) => ctx.$t("activerecord.models.contract.other"),
          url: "/contracts",
          active: (ctx) =>
            itemActive({
              context: ctx,
              controllers: ["companies/contracts"],
            }),
          badge: {
            component: "SidebarDocumentsBadge",
            componentProps: {
              mode: "contracts",
            },
          },
          enabled: (ctx) =>
            ctx.platformEnabledAndSubscribed("contracts") &&
            ctx.platformEnabled("documents"),
        },
        {
          text: (ctx) => ctx.$t("activerecord.models.annual_report.other"),
          url: "/annual_reports",
          active: (ctx) =>
            itemActive({
              context: ctx,
              controllers: ["companies/annual_reports"],
            }),
          enabled: (ctx) =>
            ctx.platformEnabledAndSubscribed("annual_report") &&
            (ctx.$theme
              .show_annual_reports_without_consolidated_annual_reports ||
              ctx.platformEnabledAndSubscribed("consolidated_annual_reports")),
        },
      ],
    },

    // Financials
    {
      text: (ctx) => ctx.$t("components.nav.board_room.financials"),
      url: "#",
      icon: "fa-chart-line",

      children: [
        {
          text: (ctx) =>
            ctx.$t("components.nav.board_room.financials_dashboard"),
          url: "/financials/dashboards",
          active: (ctx) =>
            itemActive({
              context: ctx,
              controllers: ["companies/financials/dashboards"],
            }),
          enabled: (ctx) =>
            ctx.policies.can_index_economy &&
            ctx.platformEnabledAndSubscribed("economy"),
        },
        {
          text: (ctx) =>
            ctx.$t("views.companies.economy.nav.income_statement_report"),
          url: "/financials/income_statements",
          active: (ctx) =>
            itemActive({
              context: ctx,
              controllers: ["companies/financials/income_statements"],
            }),
          enabled: (ctx) =>
            ctx.policies.can_index_economy &&
            ctx.platformEnabledAndSubscribed("economy"),
        },
        {
          text: (ctx) =>
            ctx.$t("views.companies.economy.nav.balance_sheet_report"),
          url: "/financials/balance_sheet_reports",
          active: (ctx) =>
            itemActive({
              context: ctx,
              controllers: ["companies/financials/balance_sheet_reports"],
            }),
          enabled: (ctx) =>
            ctx.policies.can_index_economy &&
            ctx.platformEnabledAndSubscribed("economy"),
        },
        {
          text: (ctx) => ctx.$t("views.companies.economy.nav.cash_flow_report"),
          url: "/financials/cash_flow_reports",
          active: (ctx) =>
            itemActive({
              context: ctx,
              controllers: ["companies/financials/cash_flow_reports"],
            }),
          enabled: (ctx) =>
            ctx.policies.can_index_economy &&
            ctx.platformEnabledAndSubscribed("economy"),
        },
        {
          text: (ctx) => ctx.$t("views.companies.economy.nav.budget.title"),
          url: "/financials/budgets",
          active: (ctx) =>
            itemActive({
              context: ctx,
              controllers: ["companies/financials/budgets"],
            }),
          enabled: (ctx) =>
            ctx.policies.can_index_economy &&
            ctx.platformEnabled("financials_budget") &&
            ctx.platformEnabledAndSubscribed("economy"),
        },
        {
          text: (ctx) =>
            ctx.$t("views.companies.economy.nav.economy_liquidity_forecast"),
          url: "/financials/liquidity_forecasts",
          active: (ctx) =>
            itemActive({
              context: ctx,
              controllers: ["companies/financials/liquidity_forecasts"],
            }),
          enabled: (ctx) =>
            ctx.policies.can_index_economy &&
            ctx.platformEnabledAndSubscribed("economy") &&
            ctx.platformEnabledAndSubscribed("economy_liquidity_forecast"),
        },
        {
          text: (ctx) =>
            ctx.$t("components.nav.board_room.financials_components"),
          url: "/financials/components",
          active: (ctx) =>
            itemActive({
              context: ctx,
              controllers: [
                "companies/financials/components",
                "companies/financials/components/tables",
                "companies/financials/components/graphs",
                "companies/financials/components/widgets",
              ],
            }),
          enabled: (ctx) =>
            ctx.policies.can_upload_economy &&
            ctx.platformEnabledAndSubscribed("economy") &&
            ctx.platformEnabledAndSubscribed("economy-kpi"),
        },
        {
          text: (ctx) =>
            ctx.$t("components.nav.board_room.financials_data_management"),
          url: "/financials/data_management",
          active: (ctx) =>
            itemActive({
              context: ctx,
              controllers: [
                "companies/financials/data_management",
                "companies/financials/data_management/kpis",
                "companies/financials/data_management/data_variables",
                "companies/financials/data_management/variables",
              ],
            }),
          enabled: (ctx) =>
            ctx.policies.can_upload_economy &&
            ctx.platformEnabledAndSubscribed("economy"),
        },
      ],
    },

    // Corporate group
    {
      text: (ctx) =>
        ctx.$t("components.nav.board_room.corporate_group_financials"),
      url: "#",
      icon: "fa-sitemap",

      children: [
        {
          text: (ctx) =>
            ctx.$t("components.nav.board_room.financials_dashboard"),
          url: "/corporate_groups/dashboards",
          active: (ctx) =>
            itemActive({
              context: ctx,
              controllers: ["companies/corporate_groups/dashboards"],
            }),
          enabled: (ctx) =>
            ctx.policies.can_index_economy &&
            ctx.platformEnabledAndSubscribed("economy") &&
            ctx.platformEnabledAndSubscribed(
              "economy-corporate-group-consolidation"
            ),
        },
        {
          text: (ctx) =>
            ctx.$t("views.companies.economy.nav.income_statement_report"),
          url: "/corporate_groups/income_statements",
          active: (ctx) =>
            itemActive({
              context: ctx,
              controllers: ["companies/corporate_groups/income_statements"],
            }),
          enabled: (ctx) =>
            ctx.policies.can_index_economy &&
            ctx.platformEnabledAndSubscribed("economy") &&
            ctx.platformEnabledAndSubscribed(
              "economy-corporate-group-consolidation"
            ),
        },
        {
          text: (ctx) =>
            ctx.$t("views.companies.economy.nav.balance_sheet_report"),
          url: "/corporate_groups/balance_sheet_reports",
          active: (ctx) =>
            itemActive({
              context: ctx,
              controllers: ["companies/corporate_groups/balance_sheet_reports"],
            }),
          enabled: (ctx) =>
            ctx.policies.can_index_economy &&
            ctx.platformEnabledAndSubscribed("economy") &&
            ctx.platformEnabledAndSubscribed(
              "economy-corporate-group-consolidation"
            ),
        },
        {
          text: (ctx) => ctx.$t("views.companies.economy.nav.cash_flow_report"),
          url: "/corporate_groups/cash_flow_reports",
          active: (ctx) =>
            itemActive({
              context: ctx,
              controllers: ["companies/corporate_groups/cash_flow_reports"],
            }),
          enabled: (ctx) =>
            ctx.policies.can_index_economy &&
            ctx.platformEnabledAndSubscribed("economy") &&
            ctx.platformEnabledAndSubscribed(
              "economy-corporate-group-consolidation"
            ),
        },
        {
          text: (ctx) => ctx.$t("views.companies.economy.nav.budget.title"),
          url: "/corporate_groups/budgets",
          active: (ctx) =>
            itemActive({
              context: ctx,
              controllers: ["companies/corporate_groups/budgets"],
            }),
          enabled: (ctx) =>
            ctx.policies.can_index_economy &&
            ctx.platformEnabledAndSubscribed("economy") &&
            ctx.platformEnabledAndSubscribed(
              "economy-corporate-group-consolidation"
            ),
        },
        {
          text: (ctx) =>
            ctx.$t("components.nav.board_room.financials_consolidation"),
          url: "/corporate_groups/consolidation",
          active: (ctx) =>
            itemActive({
              context: ctx,
              controllers: ["companies/corporate_groups/consolidations"],
            }),
          enabled: (ctx) => {
            return (
              ctx.policies.can_upload_economy &&
              ctx.platformEnabledAndSubscribed("economy") &&
              ctx.platformEnabledAndSubscribed(
                "economy-corporate-group-consolidation"
              )
            );
          },
        },
        {
          text: (ctx) =>
            ctx.$t("components.nav.board_room.consolidated_annual_report"),
          url: "/corporate_groups/consolidated_annual_reports",
          active: (ctx) =>
            itemActive({
              context: ctx,
              controllers: [
                "companies/corporate_groups/consolidated_annual_reports",
              ],
            }),
          enabled: (ctx) => {
            return (
              ctx.policies.can_upload_economy &&
              ctx.platformEnabledAndSubscribed("economy") &&
              ctx.platformEnabledAndSubscribed("consolidated_annual_reports") &&
              ctx.platformEnabledAndSubscribed(
                "economy-corporate-group-consolidation"
              )
            );
          },
        },
        {
          text: (ctx) =>
            ctx.$t("components.nav.board_room.financials_components"),
          url: "/corporate_groups/components",
          active: (ctx) =>
            itemActive({
              context: ctx,
              controllers: [
                "companies/corporate_groups/components",
                "companies/corporate_groups/components/tables",
                "companies/corporate_groups/components/graphs",
                "companies/corporate_groups/components/widgets",
                "companies/corporate_groups/components/group_trees",
              ],
            }),
          enabled: (ctx) => {
            return (
              ctx.policies.can_upload_economy &&
              ctx.platformEnabledAndSubscribed("economy") &&
              ctx.platformEnabledAndSubscribed("economy-kpi") &&
              ctx.platformEnabledAndSubscribed(
                "economy-corporate-group-consolidation"
              )
            );
          },
        },
        {
          text: (ctx) =>
            ctx.$t("components.nav.board_room.financials_data_management"),
          url: "/corporate_groups/data_management",
          active: (ctx) =>
            itemActive({
              context: ctx,
              controllers: [
                "companies/corporate_groups/data_management",
                "companies/corporate_groups/data_management/kpis",
                "companies/corporate_groups/data_management/data_variables",
                "companies/corporate_groups/data_management/variables",
              ],
            }),
          enabled: (ctx) =>
            ctx.policies.can_upload_economy &&
            ctx.platformEnabledAndSubscribed("economy") &&
            ctx.platformEnabledAndSubscribed(
              "economy-corporate-group-consolidation"
            ),
        },
      ],
    },

    // Reports
    {
      text: (ctx) => ctx.$t("activerecord.models.report.other"),
      url: "#",
      icon: "fa-layer-group",
      active: (ctx) =>
        itemActive({
          context: ctx,
          controllers: [
            "companies/reports/overviews",
            "companies/reports",
            "companies/reports/templates",
          ],
        }),

      children: [
        {
          text: (ctx) => ctx.$t("components.nav.board_room.reports_overview"),
          url: "/reports/overview",
          active: (ctx) =>
            itemActive({
              context: ctx,
              controllers: ["companies/reports/overviews"],
            }),
          enabled: (ctx) => ctx.platformEnabledAndSubscribed("reports"),
        },
        {
          text: (ctx) => ctx.$t("components.nav.board_room.all_reports"),
          url: "/reports",
          active: (ctx) =>
            itemActive({
              context: ctx,
              controllers: ["companies/reports"],
              actions: ["index"],
            }),
          enabled: (ctx) => ctx.platformEnabledAndSubscribed("reports"),
        },
        {
          text: (ctx) => ctx.$t("models.report.create_report"),
          url: "/reports",
          active: false, // This can never be active
          linkAttrs: { "data-method": "post" },
          enabled: (ctx) =>
            ctx.policies.can_create_report &&
            ctx.platformEnabledAndSubscribed("reports"),
        },
        {
          text: (ctx) => ctx.$t("activerecord.models.report_template.other"),
          url: "/reports/templates",
          active: (ctx) =>
            itemActive({
              context: ctx,
              controllers: ["companies/reports/templates"],
            }),
          enabled: (ctx) =>
            ctx.policies.can_create_report &&
            ctx.platformEnabledAndSubscribed("reports"),
        },
      ],
    },

    // Shareholder Register
    {
      text: (ctx) => ctx.$t("activerecord.models.shareholder_register.one"),
      url: "/shareholder_register",
      icon: "fa-briefcase",
      active: (ctx) =>
        itemActive({
          context: ctx,
          controllers: [
            "companies/shareholder_registers",
            "companies/shareholder_registers/actions",
            "companies/shareholder_registers/transactions",
            "companies/shareholder_registers/shareholders",
            "companies/shareholder_registers/register_series",
          ],
        }),
      enabled: (ctx) => {
        return (
          ctx.platformEnabledAndSubscribed("shareholder_register") &&
          ctx.policies.can_show_shareholder_register
        );
      },
    },

    // Message Threads
    {
      text: (ctx) => ctx.$t("activerecord.models.message_thread.other"),
      url: "/message_threads",
      icon: "fa-comments",
      active: (ctx) =>
        itemActive({
          context: ctx,
          controllers: ["companies/message_threads"],
        }),
      badge: {
        component: "UnreadMessagesBadge",
        componentProps: {
          companyId: (ctx) => ctx.$currentCompany.id,
          wrapperCss: (ctx) =>
            ctx.collapsed ? "position-absolute" : "ml-auto mr-1",
          css: (ctx) =>
            `badge badge-pill badge-notification ${
              ctx.collapsed ? "position-static" : ""
            }`,
        },
      },
      enabled: (ctx) => ctx.platformEnabled("message_threads"),
    },

    // Notes
    {
      text: (ctx) => ctx.$t("activerecord.models.note.other"),
      url: "/notes/new",
      icon: "fa-pencil",
      active: (ctx) =>
        itemActive({
          context: ctx,
          controllers: ["companies/notes"],
        }),
      enabled: (ctx) => ctx.platformEnabled("notes"),
    },

    // Tools
    {
      text: (ctx) => ctx.$t("components.nav.board_room.tools"),
      url: "#",
      icon: "fa-tools",
      promoteSingleChildText: true,

      children: [
        {
          text: (ctx) => ctx.$t("components.nav.board_room.template_packages"),
          url: "/template_packages",
          active: (ctx) =>
            itemActive({
              context: ctx,
              controllers: [
                "companies/template_packages",
                "companies/template_packages/invitations",
              ],
            }),
          enabled: (ctx) =>
            ctx.policies.can_index_template_packages &&
            (ctx.platformEnabled("template_packages") ||
              ctx.flipperFlag("fortnox_template_packages")),
        },
        {
          text: (ctx) => ctx.$t("activerecord.models.evaluation.other"),
          url: "/evaluation_forms",
          active: (ctx) =>
            itemActive({
              context: ctx,
              controllers: [
                "companies/evaluation_forms",
                "companies/evaluation_forms/archives",
                "companies/evaluation_forms/invitations",
                "companies/evaluation_forms/reports",
                "companies/evaluation_forms/report_invitations",
              ],
            }),
          enabled: (ctx) => ctx.platformEnabledAndSubscribed("evaluation"),
          promotedIcon: "fa-clipboard-question",
        },
        {
          text: (ctx) => ctx.$t("models.admin_panel.sbs_manager"),
          url: (ctx) => ctx.sbsManagerLink,
          absoluteUrl: true,
          icon: "fa-external-link",
          active: () => false,
          enabled: (ctx) => {
            return (
              (ctx.platformEnabled("inquiries") && ctx.sbsManagerLink) ||
              (ctx.$platform.theme.theme_name === "bank1" && ctx.sbsManagerLink)
            );
          },
        },
      ],
    },

    // Administration
    {
      text: (ctx) => ctx.$t("components.nav.board_room.administration"),
      url: "#",
      icon: "fa-users-cog",
      promoteSingleChildText: true,

      children: [
        {
          text: (ctx) => ctx.$t("components.nav.board_room.company_settings"),
          url: "/edit",
          active: (ctx) =>
            itemActive({
              context: ctx,
              controllers: ["companies/companies", "companies/settings"],
              exclude: [
                {
                  controller: "companies/companies",
                  action: "show",
                },
              ],
            }),
          enabled: (ctx) => {
            return (
              ctx.platformEnabled(
                "company_settings",
                "company_self_administration"
              ) && ctx.policies.can_update_company
            );
          },
        },
        {
          text: (ctx) => ctx.$t("components.nav.board_room.company_settings"),
          url: "/settings",
          active: (ctx) =>
            itemActive({
              context: ctx,
              controllers: ["companies/companies", "companies/settings"],
              exclude: [
                {
                  controller: "companies/companies",
                  action: "show",
                },
              ],
            }),
          enabled: (ctx) => {
            return (
              !ctx.platformEnabled(
                "company_settings",
                "company_self_administration"
              ) &&
              ctx.platformEnabled("company_advanced_settings") &&
              ctx.policies.can_update_company
            );
          },
        },
        {
          text: (ctx) => ctx.$t("activerecord.models.user.other"),
          url: "/memberships",
          active: (ctx) =>
            itemActive({
              context: ctx,
              controllers: ["companies/memberships"],
            }),
          enabled: (ctx) => ctx.policies.can_index_memberships,
        },
        {
          text: (ctx) => ctx.$t("activerecord.models.token.other"),
          url: "/tokens",
          active: (ctx) =>
            itemActive({
              context: ctx,
              controllers: ["companies/tokens"],
            }),
          enabled: (ctx) => {
            return (
              ctx.platformEnabledAndSubscribed("contracts") &&
              ctx.policies.can_show_company
            );
          },
        },
        {
          text: (ctx) => ctx.$t("application.shop"),
          url: "/shop",
          active: (ctx) =>
            itemActive({
              context: ctx,
              controllers: ["companies/shop"],
            }),
          enabled: (ctx) => {
            return (
              ctx.platformEnabled("shop") && ctx.policies.can_update_company
            );
          },
        },
        {
          text: (ctx) => ctx.$t("application.integrations"),
          url: "/integrations",
          active: (ctx) =>
            itemActive({
              context: ctx,
              controllers: ["companies/integrations"],
            }),
          enabled: (ctx) =>
            ctx.hasIntegrationSubscriptions &&
            ctx.policies.can_handle_integrations,
        },
        {
          text: (ctx) => ctx.$t("application.payment_integrations"),
          url: "/payment_integrations",
          active: (ctx) =>
            itemActive({
              context: ctx,
              controllers: ["companies/payment_integrations"],
            }),
          enabled: (ctx) => ctx.platformEnabled("payment_integrations"),
        },
        {
          text: (ctx) => ctx.$t("activerecord.models.company_group.one"),
          url: "/group",
          active: (ctx) =>
            itemActive({
              context: ctx,
              controllers: ["companies/group", "companies/group/invitations"],
            }),
          enabled: (ctx) => {
            const isFortnox = ctx.$theme.name === "fortnox";
            const companyGroupsEnabled = ctx.platformEnabled("company_groups");
            const corporateGroupConsolidationEnabled =
              ctx.platformEnabledAndSubscribed(
                "economy-corporate-group-consolidation"
              );

            return (
              ctx.policies.can_handle_corporate_group &&
              ((isFortnox && corporateGroupConsolidationEnabled) ||
                (!isFortnox && companyGroupsEnabled))
            );
          },
        },
      ],
    },
  ],
};
