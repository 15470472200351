<template>
  <div>
    <be-dropdown
      v-be-hover="onHover"
      variant="light"
      class="w-100"
      toggle-class="text-decoration-none"
      menu-class="dropdown-organization-select"
      no-caret
      no-flip
      @shown="focusSearch"
    >
      <template #button-content>
        <i class="fas fa-grid mr-md-2 text-reset" />

        <span class="d-none d-md-inline">
          {{ $t("application.my_organizations") }}</span
        >

        <be-badge
          v-if="invitations.length > 0"
          variant="notification"
          class="ml-1"
        >
          {{ invitations.length }}
        </be-badge>
      </template>

      <be-dropdown-item v-if="!hasLoadedOrganizations">
        <be-spinner>
          {{ $t("buttons.states.loading") }}
        </be-spinner>
      </be-dropdown-item>

      <template v-else>
        <be-dropdown-item
          v-if="invitations.length > 0"
          href="/home/invitations"
        >
          {{ $t("views.layouts.header.invitations") }}
          <div class="float-right align-middle">
            <be-badge variant="notification">
              {{ invitations.length }}
            </be-badge>
          </div>
        </be-dropdown-item>

        <be-dropdown-text class="bg-white" text-class="px-0">
          <be-input-group>
            <be-input-group-prepend>
              <be-input-group-text class="border-0 bg-white">
                <be-spinner
                  v-if="searching"
                  :label="$t('buttons.states.loading')"
                />

                <i v-else class="fal fa-search" />
              </be-input-group-text>
            </be-input-group-prepend>

            <be-form-input
              id="context-filter"
              ref="search"
              v-model="searchQuery"
              type="search"
              class="border-0 rounded-0"
              :placeholder="
                $t(
                  'components.shared.layouts.context_switcher.search.placeholder'
                )
              "
              autocomplete="off"
              @input="search"
            />
          </be-input-group>
        </be-dropdown-text>

        <be-dropdown-text
          v-if="!companies.length && !searchQuery"
          class="bg-white text-wrap"
          text-class="text-muted text-center pt-3 pb-0 text-nowrap"
        >
          {{
            $t("components.shared.layouts.context_switcher.no_organizations")
          }}
        </be-dropdown-text>

        <be-dropdown-text
          v-if="!companies.length && !adminPanels.length && searchQuery"
          class="bg-white text-wrap"
          text-class="text-muted text-center pt-3 pb-0 text-nowrap"
        >
          {{
            $t("components.shared.layouts.context_switcher.search.no_results")
          }}
        </be-dropdown-text>

        <be-dropdown-group
          v-if="adminPanels.length > 0"
          header-class="d-flex justify-content-between align-items-center"
          no-divider
        >
          <template #header>
            <span>{{ $t("views.layouts.header.admin_panels") }}</span>
          </template>

          <be-dropdown-item
            v-for="adminPanel in adminPanels"
            :key="`admin-panel-${adminPanel.id}`"
            :href="`/admin_panel/${adminPanel.id}`"
          >
            <div class="row flex-nowrap">
              <div class="col">{{ adminPanel.title }}</div>

              <div class="col-auto text-muted align-middle">
                <i
                  v-if="adminPanel.is_favorite"
                  v-be-tooltip="
                    $t(
                      'components.shared.layouts.context_switcher.favorites',
                      1
                    )
                  "
                  class="fal fa-heart fa-fw mr-1"
                />

                <i
                  v-be-tooltip="$t('views.layouts.header.require_mfa')"
                  class="fal fa-key fa-fw mr-1"
                />

                <i class="fal fa-angle-right" />
              </div>
            </div>
          </be-dropdown-item>
        </be-dropdown-group>

        <be-dropdown-group
          v-if="companies.length > 0"
          header-class="d-flex justify-content-between align-items-center"
          no-divider
        >
          <template #header>
            <span>{{ $platform.organizations_title }}</span>
          </template>

          <be-dropdown-item
            v-for="company in companies"
            :key="company.nanoid"
            :href="url('/', { nanoid: company.nanoid })"
          >
            <div class="row flex-nowrap">
              <div class="col">
                {{ company.title }}
                <small
                  v-if="company.admin_panel_title"
                  class="d-block text-muted"
                >
                  {{ company.admin_panel_title }}
                </small>
              </div>

              <div
                class="col-auto text-muted d-flex align-items-center align-middle"
              >
                <i
                  v-if="company.no_subscription"
                  v-be-tooltip="$t('views.layouts.header.no_subscription')"
                  class="fal fa-pause-circle fa-fw mr-1"
                />

                <i
                  v-if="company.require_mfa"
                  v-be-tooltip="$t('views.layouts.header.require_mfa')"
                  class="fal fa-key fa-fw mr-1"
                />

                <i
                  v-if="company.is_favorite"
                  v-be-tooltip="
                    $t(
                      'components.shared.layouts.context_switcher.favorites',
                      1
                    )
                  "
                  class="fal fa-heart fa-fw mr-1"
                />

                <i
                  v-if="!company.is_visible"
                  v-be-tooltip="
                    $t('components.shared.layouts.context_switcher.invisible')
                  "
                  class="fal fa-eye-slash fa-fw mr-1"
                />

                <i class="fal fa-angle-right" />
              </div>
            </div>
          </be-dropdown-item>
        </be-dropdown-group>

        <be-dropdown-header
          v-if="companyPagination && companyPagination.pages > 1"
          class="text-muted"
        >
          {{
            $t(
              "components.shared.layouts.context_switcher.search_for_more_w_count_w_total",
              {
                count: companies.length,
                total: companyPagination.count,
              }
            )
          }}
        </be-dropdown-header>

        <be-dropdown-text class="py-2">
          <div class="d-flex">
            <be-button
              v-if="
                platformEnabled('create_new_company') &&
                adminPanels.length === 0 &&
                companies.length === 0
              "
              class="text-nowrap"
              href="/companies/new"
              variant="light"
              icon="fa-plus"
              block
            >
              {{ $t("application.add_new_company") }}
            </be-button>

            <be-button
              v-if="adminPanels.length > 0 || companies.length > 0"
              class="text-nowrap"
              href="/users/organizations"
              variant="light"
              icon="fa-cog"
              block
            >
              {{
                $t(
                  "components.shared.layouts.context_switcher.manage_organizations"
                )
              }}
            </be-button>
          </div>
        </be-dropdown-text>
      </template>
    </be-dropdown>
  </div>
</template>
<script>
import { EventBus } from "@/event-bus";
import { mapGetters, mapActions } from "vuex";
import debounce from "lodash/debounce";

export default {
  props: {
    adminPanelId: {
      type: String,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      searchQuery: "",
      locallyLoaded: false,
      searchDebounce: debounce(this.searchOrganizations, 300),
      searching: false,
    };
  },

  computed: {
    ...mapGetters("context_switcher", {
      adminPanels: "getAdminPanels",
      adminPanelPagination: "getAdminPanelPagination",
      companies: "getCompanies",
      companyPagination: "getCompanyPagination",
      invitations: "getPendingGroupOwnerships",
      isLoadingOrganizations: "isLoadingOrganizations",
      hasLoadedOrganizations: "hasLoadedOrganizations",
    }),
  },

  beforeMount() {
    this.fetchPendingGroupOwnerships();
  },

  mounted() {
    EventBus.on("context_switcher:reload", () => {
      this.load();
    });
  },

  methods: {
    ...mapActions("context_switcher", [
      "fetchOrganizations",
      "fetchPendingGroupOwnerships",
    ]),

    search(query) {
      this.searching = true;
      this.searchDebounce(query);
    },

    async searchOrganizations(query) {
      this.searching = true;

      await this.fetchOrganizations(query);

      this.searching = false;
    },

    /**
     *  Focus the search input if enabled.
     */
    focusSearch() {
      // Wait for the input to be rendered
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.$refs?.search?.focus();
        });
      });
    },

    async onHover() {
      if (this.locallyLoaded) {
        return;
      }

      this.load();
    },

    async load() {
      if (this.isLoadingOrganizations) {
        return;
      }

      await this.fetchOrganizations(this.searchQuery);

      this.locallyLoaded = true;
    },
  },
};
</script>
